import React from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Navigation from "./components/Navigation"
import Footer from "./components/Footer"
import Home from "./components/Home"
import Imprint from "./components/Imprint"
import Artist from "./components/Artist"
import Repertoire from "./components/Repertoire"
import Solo from "./components/Solo"
import Workshops from "./components/Workshops"
import Upcoming from "./components/Upcoming"
import Euforia from "./components/Euforia"

function App() {
    return (
        <Router>
            <div className={"bg-black"}>
                <Navigation/>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path={"/imprint"} element={<Imprint/>}/>
                    <Route path={"/artist"} element={<Artist/>}/>
                    <Route path={"/repertoire"} element={<Repertoire/>}/>
                    <Route path={"/solo"} element={<Solo/>}/>
                    <Route path={"/workshops"} element={<Workshops/>}/>
                    <Route path={"/upcoming"} element={<Upcoming/>}/>
                    <Route path={"/euforia"} element={<Euforia/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
                <Footer/>
            </div>
        </Router>
    )
}

export default App

function NoMatch() {
    return (
        <div>
            <h3 className={"text-center text-white text-xl font-thin font-sans antialised pt-16"}>Die Seite existiert nicht.
            </h3>
        </div>
    );
}
