import {Component} from "react";
import axios from "axios";

class Upcoming extends Component {
    state = {
        events: []
    }

    componentDidMount() {
        this.resetState()
    }

    getEvents = () => {
        axios.get(process.env.REACT_APP_API_URL + "/event/show/").then(res => this.setState({events: res.data}))
    }

    resetState = () => {
        this.getEvents()
    }

    render() {
        const events = this.state.events
        return (
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <h1 className={"font-thin break-words font-sans text-white text-5xl antialiased pt-8"}>Alle Veranstaltungen</h1>
                {events.length > 0 &&
                    <div>
                        {events.map((item) => (
                            <div>
                                <h1 className={"pt-16 text-left text-accent text-3xl font-sans font-thin antialiased"}>{item.header_text}</h1>
                                <h3 className={"pt-4 text-left text-white text-2xl font-sans font-thin antialiased"}>{new Date(item.date).toLocaleDateString("de-DE", {year: 'numeric', month: 'long', day: '2-digit'})}, {new Date(item.date).toLocaleTimeString("de-DE", {hour: 'numeric', minute: 'numeric'})} Uhr<br/>{item.location}</h3>
                                {item.description.length > 0 &&
                                    <p className={"pt-4 text-white text-md font-serif"} dangerouslySetInnerHTML={{__html: item.description.replace(/\n/g, "<br/>")}}></p>
                                }
                            </div>
                        ))}
                    </div>
                }
                <p className={"pt-12 text-white text-md font-serif"}>Weitere Infos zu EUFORIA finden Sie unter <a href={"/euforia"} className={"text-accent font-serif text-md"}>pantomime-jomi.de/euforia</a>.</p>
                <p className={"mt-12 mb-4 text-gray-500 text-md font-serif"}>Email-Anfragen zu Veranstaltungen können Sie senden an <a href={"mailto:buero@pantomime-jomi.de"} className={"text-white text-md font-serif"}>buero@pantomime-jomi.de</a>.</p>
            </div>
        )
    }
}

export default Upcoming