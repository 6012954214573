import {Component} from "react"
import axios from "axios"
import Top from "./home/Top"
import Artist from "./home/Artist"
import Repertoire from "./home/Repertoire"
import Events from "./home/Events"
import Gallery from "./home/Gallery"
import Contact from "./home/Contact"
import EuforiaCatcher from "./home/EuforiaCatcher";

class Home extends Component {
    state = {
        events: []
    }

    componentDidMount() {
        this.resetState()
    }

    getEvents = () => {
        axios.get(process.env.REACT_APP_API_URL + "/event/list/").then(res => this.setState({events: res.data}))
    }

    resetState = () => {
        this.getEvents()
    }

    render() {
        return (
            <div>
                <EuforiaCatcher/>
                <Top/>
                <Artist/>
                <Repertoire/>
                <Events
                    events={this.state.events}
                    resetState={this.resetState}
                />
                <Gallery/>
                <Contact/>
            </div>
        )
    }
}

export default Home