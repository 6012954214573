import typo from "../../img/euforia/typo.png"

function EuforiaCatcher() {
    return (
        <section id={"euforia"}>
            <div className={"container mx-auto max-w-screen-lg px-8 divide-y divide-gray-500"}>
                <div className={"md:mt-8 mt-4 mb-8"}>
                    <div className={"flex md:flex-row flex-col"}>
                        <div className={"flex-none content-center"}>
                            <img className={"object-contain md:max-h-[4rem] max-h-[3rem]"} src={typo}
                                 alt={"EUFORIA"}/>
                        </div>
                        <div className={"flex-grow grid content-center md:ml-8 ml-0 md:mt-0 mt-4"}>
                            <p className={"text-white text-md font-serif"}>Klänge von 1623 mit JOMIs berührender stiller
                                Kunst und überraschenden Instrumenten zum Leben erweckt</p>
                            <div>
                                <a href={"/euforia"}
                                   className={"text-white text-md p-1 mt-5 font-serif font-normal border-b-2 border-[#c51114] inline-block"}>Mehr
                                    zu EUFORIA &#8594;</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"md:mb-8 mb-12"}></div>
            </div>
        </section>
    )
}

export default EuforiaCatcher